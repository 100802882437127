'use-client';
import Navbar from "../Components/Navigation";
import { Suspense } from "react";
import GardenSpinnerLoading from "../Components/GardenSpinner";
import GardenCarouselDesktop from "../Components/GardenCarouselDesktop";
import GardenDetails from "../Components/GardenTextDetails";
import GardenPageBanner from "../Components/GardenPageBanner";
import { OneBedroomUnitCarouselDetails } from "../Utility/Objects/OneBedroomUnit";
import { IoIosMail } from "react-icons/io";
import { TbPhoneCall } from "react-icons/tb";
import Footer from "../Components/GardenResFooter"
import StandardStudioPageCover from "../Assets/Homepics/HomePicsStandardStudio/HomepicStandardStudio2.jpg"
import PagesIcons from "../Components/BedroomPagesIcons"
import SSSecondPhoto from "../Assets/Homepics/HomePicsGrandStudio/GrandStudio7.jpg"
import SSRoomPic from "../Assets/Homepics/HomePicsStandardStudio/HomepicStandardStudio2.jpg"
import GSRoomPic from "../Assets/Homepics/HomePicsGrandStudio/HomePicGrandStudio2.jpg"

const StandardStudio_ = {
    Heading: "1 Bedroom Unit",
    Details1: "Enjoy spacious comfort in our 1 Bedroom Unit, featuring a separate bedroom, a modern kitchen, and a cozy living area. With stylish decor and ample natural light, it’s perfect for both extended stays and relaxing getaways.",
    Details2: "",
    From: "",
    To: "",
    Location: ""
}

export default function StandardStudio() {
    return (
        <section className="sm:overflow-hidden">
            <Suspense fallback={<GardenSpinnerLoading />}>
                <Navbar />
                <section className="bg-center md:bg-fixed text-lagrand-white md:bg-cover bg-black bg-opacity-70 bg-blend-multiply"
                    style={{
                        backgroundImage: `url(${StandardStudioPageCover})`, // Correct way to insert the image URL
                        marginTop: ''
                    }}>
                    <GardenPageBanner Heading="1 Bedroom Unit"
                        SubHeading="" />

                </section>
                <div className="xl:grid-cols-2 xl:-ml-[170px] align-center grid-cols-2 md:grid-cols-1 md:grid md:pt-32 py-20 md:gap-[10px]  md:px-[100px] sm:gap-[100px] xl:px-[550px] px-10">
                    <GardenDetails data={StandardStudio_} />
                    <GardenCarouselDesktop data={OneBedroomUnitCarouselDetails} />

                </div>
                <PagesIcons />
                <div className="2xl:mt-[50px] 2xl:ml-[200px] md:-mb-[450px] lg:-mb-[570px] lg:-mt-[10px] md:grid-cols-2 sm:grid sm:grid-cols-1 sm:-mt-[30px] items-center justify-center tracking-widest -mt-[220px] sm:gap-[170px]">
                    <div className="2xl:ml-[200px]">
                        <h1 className="2xl:ml-[520px] xl:ml-[350px] lg:ml-[200px] lg:text-2xl md:-mt-[350px] md:text-2xl md:whitespace-nowrap sm:text-[15px] sm:ml-[110px] sm:mb-[10px] text-2xl">AT YOUR SERVICE</h1>
                        <p className="2xl:ml-[380px] xl:ml-[300px] lg:ml-[170px] lg:text-4xl md:text-3xl md:ml-[80px] md:whitespace-nowrap sm:text-[20px] text-5xl text-center font-bold sm:tracking-[10px]">A PLACE TO <br />
                            CALL HOME</p>
                        <button className="2xl:ml-[550px] xl:ml-[370px] sm:ml-[110px] mt-12 text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-5 text-center me-2 mb-2 transform hover:scale-105 hover:shadow-lg transition-transform duration-300">
                            <a href="https://www.facebook.com/people/Garden-Residences/pfbid022uAfFKqRXweRe5vEWjzc7Wz55ZpSpR8wAgqPNHepBJZcwmjoEPJhq6nBxQqpwnVbl/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >RESERVE NOW</a>
                        </button>
                    </div>
                    <div className="2xl:mb-[50px] 2xl:-ml-[180px] xl:w-[500px] lg:-ml-[150px] flex justify-center items-center min-h-screen p-4">
                        <img src={SSSecondPhoto}
                            className="2xl:h-[700px] 2xl:w-[450px] lg:h-[500px] lg:w-[300px] sm:-mt-[500px] sm:h-[420px] sm:w-[250px] w-[520px] h-auto rounded-3xl shadow-md"
                        />
                    </div>
                </div>
                <section>
                    <div className="flex justify-center pb-4 -mt-24">
                        <h1 className="tracking-widest font-bold text-3xl">OTHER ROOMS</h1>
                    </div>
                    <div className="xl:ml-[220px] xl:mr-[100px] md:grid-cols-2 sm:grid sm:grid-cols-1 flex justify-center items-center pb-7">
                        <div className="2xl:ml-[550px] mr-2 px-5 max-w-lg p-7 bg-white shadow-lg rounded-lg">
                            <div className="flex justify-center">
                                <figure className="max-w-lg ">
                                    <img className="h-auto max-w-full rounded-lg transform transition-transform duration-300 ease-in-out hover:scale-105" src={SSRoomPic} alt="image description" />
                                    <figcaption className="mt-2 text-2xl text-center text-black dark:text-gray-400 pb-4">Standard Studio</figcaption>
                                </figure>
                            </div>
                            <div className="pb-7">
                                <p className="text-md text-black mx-4 text-center">
                                    Discover comfort and style in our Standard Studio, featuring sleek furnishings, cozy bedding, and modern amenities. Enjoy bright, airy spaces with expansive city views, perfect for both business and leisure stays.
                                </p>
                            </div>
                        </div>
                        <div className="ml-2 px-4 max-w-lg p-6 bg-white shadow-lg rounded-lg">
                            <div className="flex justify-center">
                                <figure className="max-w-lg ">
                                    <img className="h-auto max-w-full rounded-lg transform transition-transform duration-300 ease-in-out hover:scale-105" src={GSRoomPic} alt="image description" />
                                    <figcaption className="mt-2 text-2xl text-center text-black dark:text-gray-400 pb-4">Deluxe Studio</figcaption>
                                </figure>
                            </div>
                            <div className="pb-7">
                                <p className="text-md text-black mx-4 text-center">
                                    Indulge in luxury with our Deluxe Studio, offering high-end finishes, a gourmet kitchenette, and a plush living area. Floor-to-ceiling windows showcase stunning views, while premium amenities ensure a refined and relaxing experience.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />

            </Suspense>
        </section>
    )
}