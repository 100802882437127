import OneBU1 from "../../Assets/Homepics/HomePicsOneBedroomUnit/OneBedroomUnit1.jpeg"
import OneBU2 from "../../Assets/Homepics/HomePicsOneBedroomUnit/OneBedroomUnit2.jpeg"
import OneBU3 from "../../Assets/Homepics/HomePicsOneBedroomUnit/9.jpeg"
import OneBU4 from "../../Assets/Homepics/HomePicsOneBedroomUnit/OneBedroomUnit4.jpeg"
import OneBU5 from "../../Assets/Homepics/HomePicsOneBedroomUnit/4.jpeg"

export const OneBedroomUnitCarouselDetails = [
{ name: "OneBUnit1", image: OneBU1 },
{ name: "OneBUnit2", image: OneBU2 },
{ name: "OneBUnit3", image: OneBU3 },
{ name: "OneBUnit4", image: OneBU4 },
{ name: "OneBUnit5", image: OneBU5 },
]