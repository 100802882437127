import GrandStudio1 from "../../Assets/Homepics/HomePicsGrandStudio/HomePicGrandStudio1.jpg"
import GrandStudio2 from "../../Assets/Homepics/HomePicsGrandStudio/HomePicGrandStudio2.jpg"
import GrandStudio3 from "../../Assets/Homepics/HomePicsGrandStudio/HomePicGrandStudio3.jpg"
import GrandStudio4 from "../../Assets/Homepics/HomePicsGrandStudio/HomePicGrandStudio4.jpg"
import GrandStudio5 from "../../Assets/Homepics/HomePicsGrandStudio/HomePicGrandStudio5.jpg"

export const GrandStudioCarouselDetails = [
{ name: "Deluxe1", image: GrandStudio1 },
{ name: "Deluxe2", image: GrandStudio2 },
{ name: "Deluxe3", image: GrandStudio3 },
{ name: "Deluxe4", image: GrandStudio4 },
{ name: "Deluxe5", image: GrandStudio5 },
]