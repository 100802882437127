import Navbar from "../Components/Navigation"
import Foot from "../Components/GardenResFooter"
import RoomsBannerPic from "../Assets/RoomsPageBannerPic.jpg"
import SSPicRoomsPage from "../Assets/Homepics/HomePicsStandardStudio/HomepicStandardStudio2.jpg"
import GSPicRoomsPage from "../Assets/Homepics/HomePicsGrandStudio/HomePicGrandStudio1.jpg"
import OneBRPicRoomsPage from "../Assets/Homepics/HomePicsGrandStudio/HomePicGrandStudio2.jpg"

export default function RoomsPage() {
    return (
        <section className="overflow-hidden">
            <div className="pb-20 bg-gray-400">
                <Navbar />
            </div>
            <div className="bg-black bg-opacity-70 bg-blend-multiply"
                style={{

                    height: '400px',
                    backgroundPosition: '10%',
                    backgroundImage: `url(${RoomsBannerPic})`, // Correct way to insert the image URL
                    marginTop: ''
                }}>
                <h1 className="2xl:text-[70px] 2xl:ml-[700px] xl:ml-[400px] sm:text-3xl md:text-4xl lg:text-5xl sm:ml-[100px] text-white ml-[400px] tracking-widest text-7xl pt-[170px]">
                    ROOM LIST
                </h1>
            </div>

            <div className="2xl:ml-[800px] xl:ml-[400px] p-[50px] -mt-[100px] items-center mb-7">
                <div className="sm:grid md:grid lg:grid items-start space-x-6">
                    <img src={SSPicRoomsPage} alt="Description of the image" className="lg:h-[450px] lg:w-[700px] sm:h-[200px] md:h-[300px] rounded-3xl w-[570px] h-[400px] mr-24" />
                    <div className="max-w-md">
                        <p className="lg:text-[30px] sm:text-[18px] pt-12 text-5xl tracking-widest text-gray-700">Standard Studio</p>
                        <p className="lg:text-[20px] sm:text-[12px] pt-12 text-md text-gray-700">Discover comfort and style in our Standard Studio, featuring sleek furnishings, cozy bedding, and modern amenities. Enjoy bright, airy spaces with expansive city views, perfect for both business and leisure stays.</p>
                        <a href="/standard-studio" className="inline-block">
                            <button className="mt-14 text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 transform transition-transform duration-300 hover:scale-110">
                                View Room
                            </button>
                        </a>
                    </div>
                </div>

                <div className="sm:grid md:grid lg:grid  mt-14 flex items-start space-x-7">
                    <img src={GSPicRoomsPage} alt="Description of the image" className="md:h-[300px] lg:h-[450px] lg:w-[700px] sm:h-[200px] rounded-3xl w-[570px] h-[400px] mr-24" />
                    <div className="max-w-md">
                        <p className="lg:text-[30px] sm:text-[18px] pt-12 text-5xl tracking-widest text-gray-700">Grand Studio</p>
                        <p className="lg:text-[20px] sm:text-[12px] pt-12 text-md text-gray-700">Indulge in luxury with our Deluxe Studio, offering high-end finishes, a gourmet kitchenette, and a plush living area. Floor-to-ceiling windows showcase stunning views, while premium amenities ensure a refined and relaxing experience.</p>
                        <a href="/deluxe-studio" className="inline-block">
                            <button className="mt-14 text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 transform transition-transform duration-300 hover:scale-110">
                                View Room
                            </button>
                        </a>
                    </div>
                </div>

                <div className="sm:grid md:grid lg:grid  mt-14 flex items-start space-x-7">
                    <img src={OneBRPicRoomsPage} alt="Description of the image" className="lg:h-[450px] lg:w-[700px] md:h-[300px] sm:h-[200px] rounded-3xl w-[570px] h-[400px] mr-24" />
                    <div className="max-w-md">
                        <p className="lg:text-[30px] sm:text-[18px] pt-12 text-5xl tracking-widest text-gray-700">1 Bedroom Unit</p>
                        <p className="lg:text-[20px] sm:text-[12px] pt-12 text-md text-gray-700">Enjoy spacious comfort in our 1 Bedroom Unit, featuring a separate bedroom, a modern kitchen, and a cozy living area. With stylish decor and ample natural light, it’s perfect for both extended stays and relaxing getaways.</p>
                        <a href="/1-bedroom-unit">
                            <button className="mt-14 text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 transform transition-transform duration-300 hover:scale-110">
                                View Room
                            </button>
                        </a>
                    </div>
                </div>
            </div>
            <Foot />

        </section>

    )
}