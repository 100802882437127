import { useEffect, useState } from "react";
import { HomeGalDescriptions } from "../Utility/HomeGalItemDescriptions";
import { HomeGalDescriptions2 } from "../Utility/HomeGalItemDescriptions";



export default function HomeGal1() {

    return (
        <section>
            <div className="">
                <h1 className="sm:text-2xl lg:text-3xl tracking-widest text-3xl text-center pt-[100px] pb-20 max-width-100%">What makes us different ?</h1>
            </div>
            <div className="xl:grid-cols-3 2xl:ml-[585px] pb-20 w-full h-full justify-center md:flex-col sm:flex-col md:ml-[320px] gap-[20px] px-[10px] max-w-screen-xl mx-auto lg:flex-col lg:grid-cols-1 xl:flex-row xl:-ml-[10px] lg:ml-[300px] flex items-center">
                {HomeGalDescriptions.map((item) => (
                    <div key={item.name} className="xl:mx-2 md:-ml-[650px] lg:flex-col lg:grid-cols-1 items-center rounded-sm pb-21 px-4 max-w-[400px]">
                        <img
                            loading="lazy"
                            src={item.image}
                            alt={item.name}
                            className="2xl:w-[500px] sm:h-[220px] md:h-[350px] md:w-[800px] rounded-3xl transform transition duration-300 hover:scale-105 shadow-xl w-full b  lg:h-[350px] object-cover object-center cursor-pointer"
                        />
                        <h2 className="text-lg font-normal text-lagrand-greenlight text-center mt-4 underline lg:text-2xl">{item.name}</h2>
                        <p className="text-gray-600 mt-2 text-center lg:text-lg text-xs">{item.description}</p>
                    </div>
                ))}
            </div>
            <div className="lg:px-4 sm:py-[20px] lg:pt-20 lg:pb-[100px] bx-full bg-slate-300">
                <p className="lg:text-4xl sm:text-2xl md:text-3xl font-serif text-gray-700 text-center">“</p>

                <p className="sm:text-sm md:text-xl lg:text-2xl text-center font-serif text-gray-700">
                    "Escape to tranquility at Garden Residence Hotel, where lush gardens <br /> meet luxurious comfort. Experience a haven of serenity, where every moment <br /> is a blend of natural beauty and elegant relaxation."
                </p>
            </div>
            <section>
                <div className="pb-20 pt-20 w-full h-full flex sm:flex-col items-center space-y-[70px] ">
                    {HomeGalDescriptions2.map((item) => (
                        <div key={item.name} className="px-12 sm:flex-col lg:flex-col xl:flex-row xl:-ml-[450px] sm:grid-cols-1 lg:grid-cols-1 flex items-center sm:items-start space-y-12 sm:space-y-0 sm:space-x-12 w-full max-w-5xl">
                            <img
                                loading="lazy"
                                src={item.image}
                                alt={item.name}
                                className="rounded-3xl transform transition duration-300 hover:scale-105 shadow-xl lg:w-[500px] lg:ml-[250px] lg:h-[350px] xl:h-[350px] xl:w-[550px] sm:h-[250px] sm:w-[500px] md:h-[350px] md:ml-[90px] object-cover object-center cursor-pointer"
                            />
                            <div className="flex flex-col justify-center w-full sm:w-[220px] text-center sm:text-lg sm:pt-[20px]">
                                <h2 className="sm:text-xl sm:-ml-[21px] sm:mb-[10px] md:ml-[150px] md:-mx-[210px] lg:mt-8 lg:ml-[330px] xl:ml-[150px] xl:text-2xl font-normal text-lagrand-greenlight underline lg:text-3xl lg:w-[220px]">
                                    {item.name}
                                </h2>
                                <p className="lg:mt-8 lg:mb-9 text-gray-600 lg:text-lg lg:ml-[270px] xl:ml-[80px] xl:text-xl sm:text-xs sm:-mx-[80px] sm:-mr-[120px] sm:w-[350px] sm:-ml-[88px] sm:mb-[14px] md:ml-[110px] md: md:text-[15px]">
                                    {item.description}
                                </p>
                                <a href={item.link}>
                                    <button
                                        type="button"
                                        className="xl:ml-[150px] lg:ml-[340px] sm:text-sm sm:ml-[30px] sm:w-[120px] md:mt-[20px] md:ml-[220px] lg:w-[200px] h-[50px] lg:text-lg font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-green-400 hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                        Find out more
                                    </button>
                                </a>
                            </div>
                        </div>
                    ))}
                </div>

            </section>
        </section>
    );
};

