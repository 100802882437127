import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Components/Home';
import Navbar from './Components/Navigation';
import About from './Components/About';
import HomeGal1 from './Components/HomeGalery1';
import StandardStudioPage from './Pages/StandardStudio';
import OneBedRoomUnitPage from './Pages/OneBedroomUnit';
import GrandStudioPage from './Pages/GrandStudio';
import RoomsPage from './Pages/RoomsPage';
import ThreeSixtyPageLobby from './Components/360Garden-Lobby';
// import ThreeSixtyPagePool1 from './Pages/360Garden-Pool-1';
// import ThreeSixtyPageInterviewOffice from './Pages/360Garden-InterviewOffice';
import './App.css'; // Ensure this file contains your CSS for transitions

function App() {
  // Rename 'location' to 'currentLocation' to avoid naming conflict

  return (
    <BrowserRouter>
  
      <Routes>
      <Route path="/" element={<Home />}/>
        <Route path="/About" element={<About />}/>
          <Route path="/standard-studio" element={<StandardStudioPage />} />
          <Route path="/1-bedroom-unit" element={<OneBedRoomUnitPage />} />
          <Route path="/grand-studio" element={<GrandStudioPage />} />
          <Route path="/rooms" element={<RoomsPage />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;


{/*
  <div className={`app-container ${isTransitioning ? 'zoom-in' : 'zoom-out'}`}></div>

  /*<div className={`app-container ${isTransitioning ? 'zoom-in' : 'zoom-out'}`}>
          <Route path="/360-tour-lobby" element={<ThreeSixtyPageLobby />} />
          {/*<Route path="/360-tour-pool-1" element={<ThreeSixtyPagePool1 />} />
          <Route path="/360-tour-InterviewRoom" element={<ThreeSixtyPageInterviewOffice />}  
          </div>

          </div>
          */}