export const GardenPopOver = [
    [
       
    ], 
    [
        { name: 'Rooms', href: 'rooms',tab:false },
        { name: 'Standard Studio', href: '/standard-studio', tab:false},
        { name: 'Grand Studio', href: '/grand-studio',tab:false },
        { name: '1 Bedroom Unit', href: '/1-bedroom-unit',tab:false },
    ],

]